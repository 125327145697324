<script setup lang="ts">
import { useRouter } from "vue-router";
const router = useRouter();
const goBack = () => {
  router.go(-2);
};
</script>
<template>
  <v-card flat class="mx-4 pb-4">
    <v-card-title class="d-flex align-center"
      ><v-icon>mdi-alert-circle-outline</v-icon>
      <span class="pl-2">403 Permission Error</span></v-card-title
    >
    <v-card-subtitle> You do not have permission to access this page. </v-card-subtitle>

    <v-card-action>
      <v-btn color="primary" variant="text" class="mt-4" @click="goBack">Go Back</v-btn>
    </v-card-action>
  </v-card>
</template>
